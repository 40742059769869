import {Link} from "@amzn/awsui-components-react";
import {TableProps} from '@amzn/awsui-components-react/polaris/table';
import {ScheduledLoadTest} from '../../loadtest-api/generated-src';

export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<ScheduledLoadTest>[] = [
    {
        id: 'configId',
        header: 'Config ID',
        cell: item => (<Link href={`#/configs/${item.configId!}`}>{item.configId}</Link>),
        sortingField: 'configId',
    },
    {
        id: 'scheduledDay',
        header: 'Scheduled Day',
        cell: item => item.scheduledDay,
        sortingField: 'scheduledDay',
    },
    {
        id: 'status',
        header: 'Status',
        cell: item => item.status,
        sortingField: 'status',
    },
    {
        id: 'actualEndTps',
        header: 'End TPS',
        cell: item => (item.actualEndTps ? item.actualEndTps : '-'),
    },
    {
        id: 'resultsLink',
        header: 'Results',
        cell: item => (<Link href={item.wiki} target='_blank'>{item.wiki}</Link>)
    }
];
