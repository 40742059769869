import {useCollection} from '@amzn/awsui-collection-hooks';
import {Button, Header, Pagination, SpaceBetween, Table, TextFilter} from "@amzn/awsui-components-react";
import {useEffect, useState} from 'react';
import {LoadTestConfig} from '../../loadtest-api/generated-src';
import LoadTestApiFactory from '../../loadtest-api/LoadTestApiFactory';
import {EmptyState, getMatchesCountText, paginationLabels} from "../common-components";
import {COLUMN_DEFINITIONS} from './table-config';

export default function ConfigsTable() {
    const [allItems, setAllItems] = useState<LoadTestConfig[]>();
    const [refreshTableFlag, setRefreshTableFlag] = useState(false);

    const LoadTestApi = LoadTestApiFactory();

    useEffect(() => {
        (async () => {
            setAllItems((await LoadTestApi.listConfigs()).data.configs);
            console.log("updated load tests table");
        })();
    }, [refreshTableFlag]);

    const {items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps} = useCollection(
        allItems || [],
        {
            filtering: {
                empty: (
                    <EmptyState
                        title="No records"
                        subtitle="No records to display."
                        action={<Button iconName='refresh'
                                        onClick={() => setRefreshTableFlag(!refreshTableFlag)}>Refresh</Button>}
                    />
                ),
                noMatch: (
                    <EmptyState
                        title="No matches"
                        subtitle="We can’t find a match."
                        action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                    />
                )
            },
            pagination: {
                pageSize: 10
            },
            sorting: {},
            selection: {}
        }
    );

    const {selectedItems} = collectionProps;
    return (
        <Table
            {...collectionProps}
            loading={!allItems}
            loadingText="Loading instances"
            selectionType="single"
            resizableColumns
            variant="full-page"
            header={
                <Header
                    counter={
                        allItems && `(${allItems.length})`
                    }
                    actions={
                        <SpaceBetween direction="horizontal" size="m">
                            {<Button
                                variant='primary'
                                disabled={selectedItems!.length == 0}
                                href={selectedItems!.length ? `#/configs/${selectedItems![0].id!}` : ``}
                            >
                                View Config
                            </Button>}
                        </SpaceBetween>
                    }
                >
                    All Load Test Configs
                </Header>
            }
            columnDefinitions={COLUMN_DEFINITIONS}
            items={items}
            pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels}/>}
            filter={
                <TextFilter
                    {...filterProps}
                    countText={getMatchesCountText(filteredItemsCount!)}
                    filteringAriaLabel="Filter records"
                />
            }
        />
    );
}
