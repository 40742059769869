import {useEffect, useState} from 'react';
import {useCollection} from '@amzn/awsui-collection-hooks';
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import Table from '@amzn/awsui-components-react/polaris/table';
import TextFilter from '@amzn/awsui-components-react/polaris/text-filter';
import {COLUMN_DEFINITIONS} from './table-config';
import LoadTestApiFactory from '../../loadtest-api/LoadTestApiFactory';
import {ScheduledLoadTest} from '../../loadtest-api/generated-src';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import {EmptyState, getMatchesCountText, paginationLabels} from "../common-components";

export default function LoadTestsTable() {
    const [allItems, setAllItems] = useState<ScheduledLoadTest[]>();
    const [refreshTableFlag, setRefreshTableFlag] = useState(false);

    const LoadTestApi = LoadTestApiFactory();

    const dateRadius = 7;

    useEffect(() => {
        (async () => {
            const startDate = new Date();
            startDate.setDate(startDate.getDate() - dateRadius);
            const endDate = new Date();
            endDate.setDate(endDate.getDate() + dateRadius);
            setAllItems((await LoadTestApi.listSchedules(startDate.toISOString(), endDate.toISOString())).data.schedules);
            console.log("updated load tests table");
        })();
    }, [refreshTableFlag]);

    const {items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps} = useCollection(
        allItems || [],
        {
            filtering: {
                empty: (
                    <EmptyState
                        title="No records"
                        subtitle="No records to display."
                        action={<Button iconName='refresh'
                                        onClick={() => setRefreshTableFlag(!refreshTableFlag)}>Refresh</Button>}
                    />
                ),
                noMatch: (
                    <EmptyState
                        title="No matches"
                        subtitle="We can’t find a match."
                        action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                    />
                )
            },
            pagination: {
                pageSize: 10
            },
            sorting: {},
            selection: {}
        }
    );

    const {selectedItems} = collectionProps;
    return (
        <Table
            {...collectionProps}
            loading={!allItems}
            loadingText="Loading instances"
            selectionType="single"
            resizableColumns
            header={
                <Header
                    counter={
                        allItems && `(${allItems.length})`
                    }
                    actions={
                        <SpaceBetween direction="horizontal" size="m">
                            {<Button
                                variant='primary'
                                disabled={selectedItems!.length == 0}
                                href={selectedItems!.length ? `#/configs/${selectedItems![0].configId!}` : ``}
                            >
                                View Config
                            </Button>}
                        </SpaceBetween>
                    }
                >
                    Recent/Upcoming Scheduled Load Tests
                </Header>
            }
            columnDefinitions={COLUMN_DEFINITIONS}
            items={items}
            pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels}/>}
            filter={
                <TextFilter
                    {...filterProps}
                    countText={getMatchesCountText(filteredItemsCount!)}
                    filteringAriaLabel="Filter records"
                />
            }
        />
    );
}
